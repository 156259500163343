import useBrowserSettings from "./useBrowserSettings";

const useOpenExternalURL = () => {
  const { isSafari, isGapp } = useBrowserSettings();
  const open = (
    url: string,
    options?: string,
    dimensions?: string,
    isAbsolute?: boolean // for sending email it is needed to be treated like an absolute url
  ) => {
    const websiteUrl = url.includes("https://globalworkandtravel.com");
    if (websiteUrl && isGapp) {
      window.location.href = url;
    } else {
      // if entered url is relative like www.google.com, since it doesn't have "http" or "https" at the beginning
      // window doesn't recognize it as absolute url and put it at the end of the current url
      const absoluteUrl = isAbsolute
        ? url
        : url.indexOf("http") === 0
        ? url
        : `http://${url}`;
      window.open(
        absoluteUrl,
        options || "_blank",
        dimensions || "noopener noreferrer"
      );
    }
  };

  return open;
};

export default useOpenExternalURL;
