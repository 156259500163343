import SvgIcon, { SvgIconProps } from "./SvgIcon";

const AnimalPawnIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M7.57338 19.5387C9.30166 20.2641 10.3782 18.7186 12.0102 18.6859C13.5953 18.7177 14.7742 20.2408 16.4471 19.5387C17.3739 19.1766 18.0078 18.2907 17.9957 17.292C17.9761 14.3075 15.2049 10.8169 12.0102 10.8134C8.79679 10.8134 5.60506 14.5466 6.04777 17.6502C6.17224 18.564 6.78795 19.2319 7.57338 19.5387Z"
        stroke="white"
        fill="transparent"
        strokeWidth="2"
      />
      <path
        d="M4.61124 11.5865C5.32569 11.5865 5.90486 11.0073 5.90486 10.2929C5.90486 9.57844 5.32569 8.99927 4.61124 8.99927C3.8968 8.99927 3.31763 9.57844 3.31763 10.2929C3.31763 11.0073 3.8968 11.5865 4.61124 11.5865Z"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M19.3889 11.5865C20.1034 11.5865 20.6826 11.0073 20.6826 10.2929C20.6826 9.57844 20.1034 8.99927 19.3889 8.99927C18.6745 8.99927 18.0953 9.57844 18.0953 10.2929C18.0953 11.0073 18.6745 11.5865 19.3889 11.5865Z"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M9.22769 7.58723C9.94215 7.58723 10.5213 7.00806 10.5213 6.29362C10.5213 5.57917 9.94215 5 9.22769 5C8.51325 5 7.93408 5.57917 7.93408 6.29362C7.93408 7.00806 8.51325 7.58723 9.22769 7.58723Z"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M14.7739 7.58723C15.4883 7.58723 16.0674 7.00806 16.0674 6.29362C16.0674 5.57917 15.4883 5 14.7739 5C14.0594 5 13.4802 5.57917 13.4802 6.29362C13.4802 7.00806 14.0594 7.58723 14.7739 7.58723Z"
        stroke="black"
        strokeWidth="0.5"
      />
    </SvgIcon>
  );
};

export default AnimalPawnIcon;
