import { FC, useState } from "react";
import cx from "classnames";
import { MobileIcon, CloseIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import {
  useBrowserSettings,
  useFeatureFlags,
  useOpenExternalURL,
  useLock,
} from "@/hooks";
import { IconButton } from "@/components/Button";
import styles from "./ApppromotionBanner.module.scss";

const AppPromotionBanner: FC = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const {
    flags: { showInstallAppBanner },
  } = useFeatureFlags();
  const { isGapp } = useBrowserSettings();
  const openExternalURL = useOpenExternalURL();
  const { isAllowedToNavigate } = useLock();

  const shouldShowBanner =
    !isGapp && showInstallAppBanner && !isClosed && isAllowedToNavigate;

  if (!shouldShowBanner) return null;

  const openAppStore = () => {
    openExternalURL(
      "https://apps.apple.com/us/app/global-work-travel/id1535421676?ign-itscg=30200&ign-itsct=apps_box_link"
    );
  };

  const openGooglePlay = () => {
    openExternalURL(
      "https://play.google.com/store/apps/details?id=com.globalworkandtravel.gapp"
    );
  };

  return (
    <div className={cx(styles.root, { [styles.expandedRoot]: isExpanded })}>
      {isExpanded ? (
        <div className={styles.expandedContainer}>
          <div
            className={styles.header}
            role="presentation"
            onClick={() => setIsExpanded(false)}
          >
            <Typography variant="body1">Get the App</Typography>
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                setIsClosed(true);
              }}
              className={styles.expandedCloseButton}
            >
              <CloseIcon className={styles.expandedCloseIcon} />
            </IconButton>
          </div>
          <div className={styles.storeIconContainer}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={`/images/google-play-icon.svg`}
              alt="icon"
              className={styles.storeIcon}
              onClick={openGooglePlay}
            />
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={`/images/app-store-icon.svg`}
              alt="icon"
              className={styles.storeIcon}
              onClick={openAppStore}
            />
          </div>
        </div>
      ) : (
        <div
          className={styles.container}
          role="presentation"
          onClick={() => setIsExpanded(true)}
        >
          <MobileIcon className={styles.mobileIcon} />
          <Typography variant="body1" color="text.contrast">
            Get the App
          </Typography>
          <IconButton
            onClick={(event) => {
              event.preventDefault();
              setIsClosed(true);
            }}
            className={styles.closeButton}
          >
            <CloseIcon className={styles.closeIcon} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default AppPromotionBanner;
