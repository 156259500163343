import { FC } from "react";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import Dialog, { DialogTitle, DialogContent } from "@/components/Dialog";
import Typography from "@/components/Typography";
import Button from "@/components/Button";
import {
  usePlanOptionsQuery,
  useInvoicesQuery,
  PlanOption,
} from "@/fetch/payment";
import { useCountDown, useFormatCurrency } from "@/hooks";
import styles from "./UpgradeDialog.module.scss";

type Props = {
  onClose: () => void;
};

const UpgradeDialog: FC<Props> = ({ onClose }) => {
  const { data: invoices, isLoading: isLoadingInvoices } = useInvoicesQuery();
  const tripInvoice = invoices?.find((i) => i.type === "trip");

  const { isLoading: isLoadingPlans, data } = usePlanOptionsQuery({
    invoiceId: tripInvoice?.id,
  });

  const { format } = useFormatCurrency();

  const { push } = useRouter();

  const isFullPlanOption = ({ option }: PlanOption) =>
    option.count === 1 && option.unit === "day" && option.interval === 1;

  const fullOption = data?.paymentOptions?.find(isFullPlanOption);

  const promotionObj = fullOption?.option.promotionObject;

  const endDate = promotionObj?.conditions.end_at || 0;
  const expireDate = promotionObj?.conditions.expire_at || 0;
  const selectedDate = endDate < expireDate ? endDate : expireDate;
  const counterDate = dayjs((selectedDate || 0) * 1000);

  const value = format(promotionObj?.amount, promotionObj?.currency);

  const { hour, minute, second } = useCountDown({ time: counterDate || 0 });

  const handleUpgrade = () => {
    push(`/experience/payments/invoice/${tripInvoice?.id}`);
  };

  return (
    <Dialog
      open
      classes={{ paper: styles.paper }}
      BackdropProps={{
        classes: { root: styles.backdrop },
      }}
    >
      <DialogTitle hasCloseButton onClose={onClose} />
      <DialogContent className={styles.content}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={"/images/payment-popup.svg"}
          alt="icon"
          className={styles.image}
        />
        <div className={styles.countdownContainer}>
          <Typography className={styles.countDownText} variant="h6">
            {hour}h {minute}m {second}s
          </Typography>
        </div>
        <Typography variant="h6" className={styles.title}>
          Upgrade to VIP & Fast-Track Your Trip
          <br /> Get our biggest discount available of {value} by clearing your
          balance before the timer expires.
        </Typography>
        <Typography
          color="text.secondary"
          variant="body1"
          className={styles.description}
        >
          As a VIP, you&apos;ll jump ahead of the many people who haven&apos;t
          yet confirmed their trip spot and, get priority Trip Coordinator
          service & better selection of available arrival dates before they fill
          up. It costs nothing to upgrade to VIP, in fact, you even get paid
          with a {value} discount!
        </Typography>
        <Button onClick={handleUpgrade} className={styles.button}>
          Get my discount
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeDialog;
