import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import produce from "immer";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { useGroupQuery } from ".";
import { GATEWAY_URL } from "./constants";
import { InfinityResult } from "./useRecentCommentsQuery";
import {
  InfinityResult as InfinityPostResult,
  Post,
} from "./useRecentPostsQuery";

type CommentVariable = {
  comment: string;
};

export type CommentResult = {
  id: number;
  post_id: number;
  cid: number;
  content: string;
  _ca: number;
  nickname?: string;
  s3_profile_pic: string;
};

type QueryError = {};

const useCreateCommentMutation = (
  postId: number,
  options?: UseMutationOptions<CommentResult, QueryError, CommentVariable>
) => {
  const { data: group } = useGroupQuery();
  const groupId = group?.id;
  const { data: personalDetails } = usePersonalDetailsQuery();
  const cid = personalDetails?.traveller.id;
  const queryClient = useQueryClient();
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  const recentPostsCacheKey = [
    `${GATEWAY_URL}/post/${groupId}/${cid}`,
    JSON.stringify({ pinned: false, adminPosts: false }),
  ];
  const pinnedPostsCacheKey = [
    `${GATEWAY_URL}/post/${groupId}/${cid}`,
    JSON.stringify({ pinned: true, pageSize: 1, pageNum: 1 }),
  ];
  const adminPostsCacheKey = [
    `${GATEWAY_URL}/post/${groupId}/${cid}`,
    JSON.stringify({ adminPosts: true }),
  ];
  const postCacheKey = [`${GATEWAY_URL}/post/${postId}`];
  const travellerPostsCacheKey = [
    `${GATEWAY_URL}/traveller/${cid}/posts/group/${groupId}/`,
  ];
  const postCommentsCacheKey = [`${GATEWAY_URL}/comment/${postId}`];

  const profilePicturePath = personalDetails?.traveller.s3_profile_pic;
  const url = `${GATEWAY_URL}/comment`;

  return useMutation<CommentResult, QueryError, CommentVariable>(
    async ({ comment }: CommentVariable) => {
      if (!postId) throw new Error("Post id not found");

      return fetchWithAuth<CommentResult>(url, {
        method: "POST",
        body: {
          post_id: postId,
          content: comment,
          cid,
        },
      });
    },
    {
      onMutate: async ({ comment }: CommentVariable) => {
        if (!cid || !personalDetails || !postId)
          throw new Error("Personal details, postId or cid not found");

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(postCommentsCacheKey);
        await queryClient.cancelQueries(recentPostsCacheKey);
        await queryClient.cancelQueries(pinnedPostsCacheKey);
        await queryClient.cancelQueries(postCacheKey);
        await queryClient.cancelQueries(travellerPostsCacheKey);
        await queryClient.cancelQueries(adminPostsCacheKey);

        // Snapshot the previous value
        const prevComments = queryClient.getQueryData(postCommentsCacheKey);
        const prevRecentPosts = queryClient.getQueryData(recentPostsCacheKey);
        const prevPinnedPosts = queryClient.getQueryData(pinnedPostsCacheKey);
        const prevPost = queryClient.getQueryData(postCacheKey);
        const prevTravellerPosts = queryClient.getQueryData(
          travellerPostsCacheKey
        );
        const prevAdminPosts = queryClient.getQueryData(adminPostsCacheKey);

        // Optimistically update to the new value
        if (prevComments)
          queryClient.setQueryData(
            postCommentsCacheKey,
            produce(prevComments, (cachedResult: InfinityResult) => {
              cachedResult.pages[0].data.results.push({
                content: comment,
                post_id: postId,
                cid: cid,
                id: 0,
                _ca: 0,
                nickname:
                  personalDetails?.traveller?.nickname ||
                  `${personalDetails?.traveller?.first_name} ${personalDetails?.traveller?.last_name}`,
                s3_profile_pic: profilePicturePath || "",
              });
            })
          );

        if (prevRecentPosts)
          queryClient.setQueryData(
            recentPostsCacheKey,
            produce(prevRecentPosts, (cachedResult: InfinityPostResult) => {
              cachedResult.pages.forEach((eachPage) => {
                eachPage?.results?.forEach((eachPost, idx, thePosts) => {
                  if (eachPost?.id === postId) {
                    thePosts[idx] = {
                      ...eachPost,
                      comments: {
                        total_comments:
                          (eachPost.comments?.total_comments || 0) + 1,
                        sample_comments: (
                          eachPost.comments?.sample_comments || []
                        ).concat({
                          content: comment,
                          post_id: postId,
                          cid: cid,
                          id: 0,
                          nickname:
                            personalDetails?.traveller?.nickname ||
                            `${personalDetails?.traveller?.first_name} ${personalDetails?.traveller?.last_name}`,
                          s3_profile_pic: profilePicturePath || "",
                        }),
                      },
                    };
                  }
                });
              });
            })
          );

        if (prevAdminPosts)
          queryClient.setQueryData(
            adminPostsCacheKey,
            produce(prevAdminPosts, (cachedResult: InfinityPostResult) => {
              cachedResult.pages.forEach((eachPage) => {
                eachPage?.results?.forEach((eachPost, idx, thePosts) => {
                  if (eachPost?.id === postId) {
                    thePosts[idx] = {
                      ...eachPost,
                      comments: {
                        total_comments:
                          (eachPost.comments?.total_comments || 0) + 1,
                        sample_comments: (
                          eachPost.comments?.sample_comments || []
                        ).concat({
                          content: comment,
                          post_id: postId,
                          cid: cid,
                          id: 0,
                          nickname:
                            personalDetails?.traveller?.nickname ||
                            `${personalDetails?.traveller?.first_name} ${personalDetails?.traveller?.last_name}`,
                          s3_profile_pic: profilePicturePath || "",
                        }),
                      },
                    };
                  }
                });
              });
            })
          );

        if (prevPinnedPosts)
          queryClient.setQueryData(
            pinnedPostsCacheKey,
            produce(prevPinnedPosts, (cachedResult: InfinityPostResult) => {
              cachedResult.pages.forEach((eachPage) => {
                eachPage?.results?.forEach((eachPost, idx, thePosts) => {
                  if (eachPost?.id === postId) {
                    thePosts[idx] = {
                      ...eachPost,
                      comments: {
                        total_comments:
                          (eachPost.comments?.total_comments || 0) + 1,
                        sample_comments: (
                          eachPost.comments?.sample_comments || []
                        ).concat({
                          content: comment,
                          post_id: postId,
                          cid: cid,
                          id: 0,
                          nickname:
                            personalDetails?.traveller?.nickname ||
                            `${personalDetails?.traveller?.first_name} ${personalDetails?.traveller?.last_name}`,
                          s3_profile_pic: profilePicturePath || "",
                        }),
                      },
                    };
                  }
                });
              });
            })
          );

        if (prevTravellerPosts)
          queryClient.setQueryData(
            travellerPostsCacheKey,
            produce(prevTravellerPosts, (cachedResult: InfinityPostResult) => {
              cachedResult.pages.forEach((eachPage) => {
                eachPage?.results?.forEach((eachPost, idx, thePosts) => {
                  if (eachPost?.id === postId) {
                    thePosts[idx] = {
                      ...eachPost,
                      comments: {
                        total_comments:
                          (eachPost.comments?.total_comments || 0) + 1,
                        sample_comments: (
                          eachPost.comments?.sample_comments || []
                        ).concat({
                          content: comment,
                          post_id: postId,
                          cid: cid,
                          id: 0,
                          nickname:
                            personalDetails?.traveller?.nickname ||
                            `${personalDetails?.traveller?.first_name} ${personalDetails?.traveller?.last_name}`,
                          s3_profile_pic: profilePicturePath || "",
                        }),
                      },
                    };
                  }
                });
              });
            })
          );

        if (prevPost)
          queryClient.setQueryData(
            postCacheKey,
            produce(prevPost, (cachedResult: Post) => {
              cachedResult.comments = {
                total_comments:
                  (cachedResult.comments?.total_comments || 0) + 1,
                sample_comments: (
                  cachedResult.comments?.sample_comments || []
                ).concat({
                  content: comment,
                  post_id: postId,
                  cid: cid,
                  id: 0,
                  nickname:
                    personalDetails?.traveller?.nickname ||
                    `${personalDetails?.traveller?.first_name} ${personalDetails?.traveller?.last_name}`,
                  s3_profile_pic: profilePicturePath || "",
                }),
              };
            })
          );

        // Return a context object with the snapshotted value
        return { prevRecentPosts, prevPost, prevTravellerPosts, prevComments };
      },
      onError: (err, content, context: any) => {
        console.error(err);
        queryClient.setQueryData(postCommentsCacheKey, context.prev);
        queryClient.setQueryData(recentPostsCacheKey, context?.prevRecentPosts);
        queryClient.setQueryData(adminPostsCacheKey, context?.prevAdminPosts);
        queryClient.setQueryData(pinnedPostsCacheKey, context?.prevPinnedPosts);
        queryClient.setQueryData(postCacheKey, context?.prevPost);
        queryClient.setQueryData(
          travellerPostsCacheKey,
          context?.prevTravellerPosts
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(postCommentsCacheKey);
        queryClient.invalidateQueries(recentPostsCacheKey);
        queryClient.invalidateQueries(adminPostsCacheKey);
        queryClient.invalidateQueries(pinnedPostsCacheKey);
        queryClient.invalidateQueries(postCacheKey);
        queryClient.invalidateQueries(travellerPostsCacheKey);
      },
      ...options,
    }
  );
};
export default useCreateCommentMutation;
