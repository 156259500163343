import Dialog, { DialogTitle, DialogContent } from "@/components/Dialog";
import Typography from "@/components/Typography";
import styles from "./InstalmentsDialog.module.scss";
import { PaymentPlan } from "@/fetch/payment";
import Table, { Columns } from "@/components/DataTable";
import dayjs from "dayjs";
import Button from "@/components/Button";

const InstalmentsDialog: React.FC<{
  onClose: () => void;
  open: boolean;
  paymentPlans: Array<PaymentPlan> | undefined;
  format: (amount: number | undefined) => string;
  onPay: (paymentPlanId: number, amount: number) => void;
}> = ({ onClose, open, paymentPlans, format, onPay }) => {
  if (!paymentPlans) return null;
  const paymentPlansToDisplay = paymentPlans.filter(
    (plan) => plan.status !== "Paid"
  );

  const firstPendingOrFailedAttemptIndex = paymentPlansToDisplay.findIndex(
    (p) => p.status === "Pending" || p.status === "Auto-charge attempt failed"
  );

  const columns: Columns<PaymentPlan> = [
    {
      headerName: "Due Date",
      selector: (row) =>
        row.date &&
        dayjs(row.date * 1000)
          .utc()
          .startOf("day")
          .format("DD MMM, YYYY"),
    },
    {
      headerName: "Status",
      selector: (row) =>
        (row?.time_remaining || 0) < 0 && row.status == "Pending"
          ? "Overdue"
          : row.status,
    },
    {
      headerName: "Amount",
      selector: (row) => format(row.amount),
    },
    {
      headerName: "Tx Amount",
      selector: (row) => format(row.paid_amount),
    },
    {
      headerName: "",
      selector: (row, index) =>
        firstPendingOrFailedAttemptIndex !== undefined &&
        index === firstPendingOrFailedAttemptIndex ? (
          <Button
            variant="text"
            className={styles.downloadButton}
            onClick={() => row.id && row.amount && onPay(row.id, row.amount)}
          >
            Pay Now
          </Button>
        ) : (
          ""
        ),
    },
  ];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle hasCloseButton onClose={onClose}>
        Instalments
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Typography variant="body1">
          Stay on track with your money goals by viewing your upcoming payments
          here. Wanting to pay a little extra this week? Hit the &apos;pay
          now&apos; button and be one step closer to paying your trip off!
        </Typography>
        <Table
          className={styles.table}
          columns={columns}
          rows={paymentPlansToDisplay}
        />
      </DialogContent>
    </Dialog>
  );
};

export default InstalmentsDialog;
