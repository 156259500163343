import { useEffect, useState } from "react";
import { useCountDown } from "@/hooks";

const useParseBannerText = (bannerText: string) => {
  const [banner, setBanner] = useState<string>(bannerText);
  const [countDownTimer, setCountDownTimer] = useState<number | undefined>(
    undefined
  );
  const { hour, minute, second } = useCountDown({ time: countDownTimer });
  const [hasBannerVariables, setHasBannerVariables] = useState<boolean>(false);

  useEffect(() => {
    const hasBannerVariables =
      bannerText.indexOf("{{") > -1 && bannerText.indexOf("}}") > -1;
    setHasBannerVariables(hasBannerVariables);
    if (hasBannerVariables) {
      const currentVariables = bannerText.substring(
        bannerText.indexOf("{{") + 2,
        bannerText.indexOf("}}")
      );
      //   const variableKey = currentVariables.substring(
      //     0,
      //     currentVariables.indexOf(":")
      //   );

      //   const variableValue = currentVariables.substring(
      //     currentVariables.indexOf(":") + 1
      //   );
      //   if (variableKey === "countDown") {
      setCountDownTimer(Number(currentVariables) * 1000);
      //   }
      const bannerFirstPart = bannerText.substring(0, bannerText.indexOf("{{"));

      const bannerSecondPart = bannerText.substring(
        bannerText.indexOf("}}") + 2
      );

      const newBanner = `${bannerFirstPart} ${bannerSecondPart}`;
      setBanner(newBanner);
    } else {
      setBanner(bannerText);
    }
  }, [bannerText]);

  return { banner, hour, minute, second, hasBannerVariables };
};

export default useParseBannerText;
