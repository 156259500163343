/* eslint-disable @next/next/no-img-element */
import Dialog, { DialogTitle, DialogContent } from "@/components/Dialog";
import Typography from "@/components/Typography";
import styles from "./TransactionHistoryDialog.module.scss";
import {
  useInvoiceHistoryQuery,
  InvoiceHistory,
  useGenerateTxPDFMutation,
  useInvoiceQuery,
} from "@/fetch/payment";
import { useInvoiceCreditsQuery, CreditItemType } from "@/fetch/credit";
import Table, { Columns } from "@/components/DataTable";
import { CircularProgress } from "@/components/Loader";
import {
  useOpenExternalURL,
  useFormatCurrency,
  useResponsive,
  useTrackers,
} from "@/hooks";
import dayjs from "dayjs";
import Button from "@/components/Button";
import { DownloadIcon } from "@/components/Icon";
import { useState } from "react";

const auditStatus: any = {
  2: "Reconciled",
  4: "Not Approved",
  8: "Under Review",
};

type InvoiceHistoryRowType = InvoiceHistory & {
  sortValue: number;
  isCredit: boolean;
};
type CreditItemRowType = CreditItemType & {
  sortValue: number;
  isCredit: boolean;
};

const TransactionHistoryDialog: React.FC<{
  onClose: () => void;
  open: boolean;
  invoiceId: number | undefined;
}> = ({ onClose, open, invoiceId }) => {
  const { data: invoiceData, isLoading: isInvoiceLoading } =
    useInvoiceQuery(invoiceId);
  const { data, isLoading } = useInvoiceHistoryQuery(invoiceId);
  const { data: creditsData, isLoading: isLoadingCredits } =
    useInvoiceCreditsQuery(invoiceId);
  const { isMobile } = useResponsive();
  const { format } = useFormatCurrency();
  const { mutateAsync: generateTxPDFMutation } = useGenerateTxPDFMutation();
  const openExternalURL = useOpenExternalURL();
  const [isTxPDFLoading, setIsTxPDFLoading] = useState<{
    [key: number]: boolean;
  }>({});
  const { track } = useTrackers();

  const generateTxPDF = async (transactionId: number) => {
    if (!invoiceId) return;
    setIsTxPDFLoading((current) => ({ ...current, [transactionId]: true }));
    const response = await generateTxPDFMutation({ invoiceId, transactionId });
    track("Receipt Downloaded", {
      eventId: "receipt-downloaded",
      transactionId,
    });
    const previewUrl = response.file.url;
    setIsTxPDFLoading((current) => ({ ...current, [transactionId]: false }));
    openExternalURL(previewUrl);
  };

  if (!invoiceId) return null;

  const invoiceCurrencySymbol = invoiceData?.invoice.currency_symbol;

  const isRefund = (transaction: InvoiceHistory) =>
    transaction.refund_status === "Processed";

  const columns: Columns<InvoiceHistoryRowType | CreditItemRowType> = [
    {
      headerName: "Date",
      selector: (row) => {
        if (row.isCredit) {
          const creditRow = row as CreditItemRowType;
          return dayjs(creditRow.ca * 1000)
            .utc()
            .startOf("day")
            .format("DD MMM, YYYY");
        }
        const currentRow = row as InvoiceHistoryRowType;
        return dayjs(currentRow.transaction_date * 1000)
          .utc()
          .startOf("day")
          .format("DD MMM, YYYY");
      },
      style: isMobile ? { width: 70 } : undefined,
    },
    {
      headerName: "Amount",
      selector: (row) => {
        if (row.isCredit) {
          const creditRow = row as CreditItemRowType;
          return format(
            Math.abs(row.amount),
            row.currency,
            invoiceCurrencySymbol
          );
        }
        const currentRow = row as InvoiceHistoryRowType;
        return format(
          currentRow.amount,
          currentRow.currency,
          currentRow.currency_symbol
        );
      },
      style: isMobile ? { paddingLeft: 4, paddingRight: 4 } : undefined,
    },
    {
      headerName: "Type",
      selector: (row) => {
        if (row.isCredit) return "Credit";
        const currentRow = row as InvoiceHistoryRowType;
        if (isRefund(currentRow)) return "Refund";
        return "Payment";
      },
    },
    {
      headerName: "Status",
      selector: (row) => {
        if (row.isCredit) return "Claimed";
        const currentRow = row as InvoiceHistoryRowType;
        if (isRefund(currentRow)) return currentRow.refund_status;
        return auditStatus[currentRow.audit_status];
      },
    },
    {
      headerName: "Receipt",
      selector: (row) => {
        if (row.isCredit) return;
        const currentRow = row as InvoiceHistoryRowType;
        if (currentRow.audit_status !== 2 && !isRefund(currentRow)) return "";
        return (
          <Button
            variant="text"
            endIcon={
              isTxPDFLoading[currentRow.trans_id] ? (
                <CircularProgress color="inherit" size="xs" />
              ) : (
                <DownloadIcon />
              )
            }
            onClick={() => generateTxPDF(currentRow.trans_id)}
            className={styles.downloadButton}
            disabled={isTxPDFLoading[currentRow.trans_id]}
          >
            {isMobile ? "" : "Receipt"}
          </Button>
        );
      },
    },
  ];

  const credits =
    creditsData?.map((each) => ({
      ...each,
      sortValue: each.ca,
      isCredit: true,
    })) || [];
  const histories =
    data?.histories.map((each) => ({
      ...each,
      sortValue: each.transaction_date,
      isCredit: false,
    })) || [];

  const rows = [...histories, ...credits].sort(
    (firstItem, secondItem) => firstItem.sortValue - secondItem.sortValue
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: styles.dialogPaper }}
    >
      <DialogTitle hasCloseButton onClose={onClose}>
        Transaction History
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Typography variant="body1">
          Looking for your previous payments? Search &amp; download them as
          receipts here. Otherwise, they’ll remain stored here in case you need
          them later.
        </Typography>
        <Table
          className={styles.table}
          columns={columns}
          rows={rows}
          isLoading={isLoading}
          showEmptyText={false}
        />
        {!Boolean(data?.histories?.length) && !isLoading && (
          <div className={styles.emptyTransactionList}>
            <img
              src="/images/empty-transaction.svg"
              alt="lock"
              className={styles.emptyListImage}
            />
            <Typography
              variant="subtitle1"
              className={styles.emptyTransactionHelperText}
            >
              No transactions
            </Typography>

            {/* <Typography variant="body2" color="text.secondary">
              try creating a new expense
            </Typography> */}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TransactionHistoryDialog;
