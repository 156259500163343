/* eslint-disable @next/next/no-img-element */
import Card, { ClickableCard } from "@/components/Card";
import PageTitle from "@/components/PageTitle";
import { Skeleton } from "@/components/Loader";
import { ChevronIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import styles from "./PlanOptions.module.scss";
import { MobileStatusIndicator } from "@/components/StatusIndicator";
import Tag from "@/components/Tag";
import { usePlanOptionsQuery, PlanOption } from "@/fetch/payment";
import { useState, useEffect } from "react";
import {
  TwoColumnTemplate,
  RightSide,
  Main,
} from "@/components/TwoColumnTemplate";
import { MiniTable } from "@/components/DataTable";
import { useTrackers } from "@/hooks";
import { useRouter } from "next/router";
import PlanInstalments from "./PlanInstalments";
import PaymentLockAlert from "./PaymentLockAlert";
import { useDialog } from "@/hooks";
import Button from "@/components/Button";

const PlanOptions: React.FC<{
  actions: Array<{ label: string; onClick: () => void; disabled?: boolean }>;
  invoiceId: number | undefined;
  format: (amount: number) => string;
  currency: string | undefined;
  symbol: string | undefined;
  onPay: (args: {
    amount: number;
    paymentPlanId?: number;
    promoCodes?: Array<string>;
    promotionAmount?: number;
  }) => void;
}> = ({ invoiceId, actions, format, onPay, currency, symbol }) => {
  const { isLoading, data } = usePlanOptionsQuery({ invoiceId });
  const { push } = useRouter();
  const [isDisplayingInstalmentOptions, setIsDisplayingInstalmentOptions] =
    useState(false);

  const isFullPlanOption = ({ option }: PlanOption) =>
    option.count === 1 && option.unit === "day" && option.interval === 1;

  const fullOption = data?.paymentOptions?.find(isFullPlanOption);

  const instalmentsOptions = data?.paymentOptions?.filter(
    (i) => !isFullPlanOption(i)
  );
  const maxSaveForInstalments = Math.max(
    ...(instalmentsOptions?.map(({ option }) => option.promotion) || []),
    0
  );
  const hasInstalmentsOptions = !!instalmentsOptions?.length;

  const { isOpen: isCardsDialogOpen } = useDialog("cards");

  const { track } = useTrackers();

  useEffect(() => {
    if (!data) return;
    track("Payment Plan Section Triggered", {
      eventId: "payment-plan-section-triggered",
      invoice: data.invoice,
      plans: data.paymentOptions,
    });
  }, [data, track]);

  if (isDisplayingInstalmentOptions || isCardsDialogOpen) {
    return (
      <PlanInstalments
        invoiceId={invoiceId}
        planOptions={instalmentsOptions}
        onGoBack={() => setIsDisplayingInstalmentOptions(false)}
        format={format}
        currency={currency}
        symbol={symbol}
      />
    );
  }

  return (
    <TwoColumnTemplate>
      <Main>
        <div className={styles.top}>
          <PageTitle
            visibleInMobile
            title={`Trip Invoice #${invoiceId}`}
            onGoBack={() => push("/experience/payments")}
          />
          <MobileStatusIndicator
            isLoading={isLoading}
            actions={actions}
            className={styles.mobileStatusIndicator}
          />
        </div>

        <PaymentLockAlert />
        {isLoading && <Skeleton height="200px" variant="rectangular" />}
        <Card>
          <Typography className={styles.header} variant="h5">
            Pay your trip how you choose
          </Typography>
          <Typography variant="body2" color="text.secondary">
            You’ve done it! You’ve taken the first step to live &amp; work
            overseas! To ensure everything runs according to plan and schedule,
            you’ll need to decide how you’d like to settle your trip balance.
          </Typography>

          <div className={styles.options}>
            {fullOption && (
              <ClickableCard
                outlined
                onClick={() =>
                  onPay({
                    amount: fullOption.instalments[0],
                    promoCodes: [fullOption.option.promotionCode],
                    promotionAmount: fullOption.option.promotion,
                  })
                }
              >
                <img
                  src="/images/payment-option-full.svg"
                  alt="full"
                  height="64px"
                  width="64px"
                />
                <div>
                  <Typography variant="h6">Pay in Full &amp; Save</Typography>
                  {fullOption.option.promotion > 0 && (
                    <Tag
                      className={styles.tag}
                      color="success"
                      variant="filled"
                      label={`Save ${format(fullOption.option.promotion)}`}
                    />
                  )}
                  <Typography variant="body2" color="text.secondary">
                    Jump the queue and get the best price available
                  </Typography>
                </div>
                <ChevronIcon />
              </ClickableCard>
            )}
            {hasInstalmentsOptions && (
              <ClickableCard
                outlined
                onClick={() => setIsDisplayingInstalmentOptions(true)}
              >
                <img
                  src="/images/payment-options.svg"
                  alt="full"
                  height="64px"
                  width="64px"
                />
                <div>
                  <Typography variant="h6">Pay in Instalments</Typography>
                  {maxSaveForInstalments > 0 && (
                    <Typography variant="body1">
                      Save up to {format(maxSaveForInstalments)}
                    </Typography>
                  )}
                  <Typography variant="body2" color="text.secondary">
                    Get your trip started with easy fortnightly instalments
                  </Typography>
                </div>
                <ChevronIcon />
              </ClickableCard>
            )}
          </div>
        </Card>
      </Main>
      <RightSide className={styles.rightSide}>
        <MiniTable
          header={<Typography variant="subtitle1">Total amount due</Typography>}
          value={
            <Typography variant="h6">
              {format(data?.invoice?.outstanding || 0)}
            </Typography>
          }
        />

        <div className={styles.actions}>
          {actions.map((action) => (
            <Button
              key={action.label}
              onClick={action.onClick}
              variant="text"
              disabled={action.disabled}
              color="primary"
            >
              {action.label}
            </Button>
          ))}
        </div>
      </RightSide>
    </TwoColumnTemplate>
  );
};

export default PlanOptions;
