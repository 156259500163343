import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { CREDIT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

enum CreditTypes {
  TRIP = "trip",
}

export type CreditItemType = {
  id: number;
  invoice_id: number;
  amount: number;
  currency: string;
  description: string;
  credit_date: number;
  type: CreditTypes;
  ca: number;
  cb: number;
  lma: number;
  lmb: number;
  cid: number;
};

type Result = Array<CreditItemType>;

type InvoiceCreditsFetchResult = {
  success: boolean;
  statusCode: number;
  response: Result;
  credit_details: {
    credit_balance: number;
    total_credit: number;
    used_credit: number;
  };
};

type QueryError = {};

const useInvoiceCreditsQuery = (
  invoiceId: number | undefined,
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const URL = `${CREDIT_ENDPOINT}/credits/invoice_credits/${invoiceId}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      if (!invoiceId) throw new Error();
      const res = await fetchWithAuth<InvoiceCreditsFetchResult>(URL);
      return res.response;
    },
    {
      ...options,
    }
  );
};
export default useInvoiceCreditsQuery;
