import SvgIcon, { SvgIconProps } from "./SvgIcon";

const StethoscopeIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M13.5 22C11.7 22 10.1667 21.3667 8.9 20.1C7.63333 18.8333 7 17.3 7 15.5V15.3502C7 15.1051 6.82164 14.8983 6.58278 14.8432C5.33386 14.555 4.28126 13.9115 3.425 12.9125C2.475 11.8042 2 10.5 2 9V5C2 3.89543 2.89543 3 4 3H4.8C4.91046 3 5 2.91046 5 2.8C5 2.35817 5.35817 2 5.8 2H6C6.55228 2 7 2.44772 7 3V5C7 5.55228 6.55228 6 6 6H5.8C5.35817 6 5 5.64183 5 5.2C5 5.08954 4.91046 5 4.8 5H4.5C4.22386 5 4 5.22386 4 5.5V9C4 10.1 4.39167 11.0417 5.175 11.825C5.95833 12.6083 6.9 13 8 13C9.1 13 10.0417 12.6083 10.825 11.825C11.6083 11.0417 12 10.1 12 9V5.5C12 5.22386 11.7761 5 11.5 5H11.2C11.0895 5 11 5.08954 11 5.2C11 5.64183 10.6418 6 10.2 6H10C9.44772 6 9 5.55228 9 5V3C9 2.44772 9.44772 2 10 2H10.2C10.6418 2 11 2.35817 11 2.8C11 2.91046 11.0895 3 11.2 3H12C13.1046 3 14 3.89543 14 5V9C14 10.5 13.525 11.8042 12.575 12.9125C11.7187 13.9115 10.6661 14.555 9.41722 14.8432C9.17836 14.8983 9 15.1051 9 15.3502V15.5C9 16.75 9.4375 17.8125 10.3125 18.6875C11.1875 19.5625 12.25 20 13.5 20C14.75 20 15.8125 19.5625 16.6875 18.6875C17.5625 17.8125 18 16.75 18 15.5V14.1821C18 13.9685 17.8622 13.7824 17.6698 13.6895C17.2333 13.4788 16.8642 13.1656 16.5625 12.75C16.1875 12.2333 16 11.65 16 11C16 10.1667 16.2917 9.45833 16.875 8.875C17.4583 8.29167 18.1667 8 19 8C19.8333 8 20.5417 8.29167 21.125 8.875C21.7083 9.45833 22 10.1667 22 11C22 11.65 21.8125 12.2333 21.4375 12.75C21.1358 13.1656 20.7667 13.4788 20.3302 13.6895C20.1378 13.7824 20 13.9685 20 14.1821V15.5C20 17.3 19.3667 18.8333 18.1 20.1C16.8333 21.3667 15.3 22 13.5 22ZM19 12C19.2833 12 19.5208 11.9042 19.7125 11.7125C19.9042 11.5208 20 11.2833 20 11C20 10.7167 19.9042 10.4792 19.7125 10.2875C19.5208 10.0958 19.2833 10 19 10C18.7167 10 18.4792 10.0958 18.2875 10.2875C18.0958 10.4792 18 10.7167 18 11C18 11.2833 18.0958 11.5208 18.2875 11.7125C18.4792 11.9042 18.7167 12 19 12Z" />
    </SvgIcon>
  );
};

export default StethoscopeIcon;
