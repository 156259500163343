import Typography from "@/components/Typography";
import styles from "./Result.module.scss";
import { CheckedIcon, CrossIcon } from "@/components/Icon";
import Grid from "@/components/Grid";
import Divider from "@/components/Divider";
import { useRouter } from "next/router";
import { useCurrentTrip, useFormatCurrency, useRoutes } from "@/hooks";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Button from "@/components/Button";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { CircularProgress } from "@/components/Loader";

const Result: React.FC<{
  message?: string;
  type: "success" | "error";
  amount?: number;
  date?: number;
  receiptId?: string;
  currency?: string | undefined;
  currencySymbol?: string | undefined;
  subMessage?: string | undefined;
  hasGoToDashboard?: boolean;
}> = ({
  message: messageProp,
  type,
  amount,
  date,
  currencySymbol,
  currency,
  hasGoToDashboard = true,
  subMessage: subMessageProp,
  receiptId,
}) => {
  const formatter = useFormatCurrency();
  const { currentTrip } = useCurrentTrip();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const { hasAccessToRoute } = useRoutes();
  const { traveller } = personalDetails || {};
  const { first_name, last_name, middle_name } = traveller || {};
  const { push } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const hasAccessToDashboard = hasAccessToRoute("Experience", "Dashboard");

  let message;
  let subMessage;
  let tableRows;

  switch (type) {
    case "success":
      message = messageProp || "Payment Completed";
      subMessage =
        subMessageProp ??
        "Your payment went through. A receipt will be emailed and you can find a record under Transaction History.";

      tableRows = [
        ["Traveller", `${first_name} ${middle_name || ""} ${last_name}`],
        ["Experience", currentTrip?.division],
        ["To", currentTrip?.destination],
      ];

      if (date) {
        tableRows.unshift(["On", dayjs.unix(date).format("L")]);
      }

      if (amount) {
        tableRows.unshift([
          "You paid",
          formatter.format(amount, currency, currencySymbol),
        ]);
      }

      if (receiptId) {
        tableRows.push(["Receipt No.", receiptId]);
      }

      break;

    case "error":
      message = messageProp || "Something went wrong, try again.";
      subMessage = subMessageProp;
      break;
  }

  useEffect(() => {
    const waitForLD = async () => {
      setIsLoading(true);
      await new Promise((resolve) =>
        setTimeout(resolve, hasAccessToDashboard ? 5000 : 20000)
      );
      setIsLoading(false);
    };
    waitForLD();
  }, []);

  return (
    <div className={styles.root}>
      {isLoading ? (
        <div className={styles.finalize}>
          <CircularProgress size="lg" />
          <Typography>Finalising...</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Please wait, this may take some time
          </Typography>
        </div>
      ) : (
        <>
          {type === "success" && (
            <CheckedIcon color="success" className={styles.icon} />
          )}
          {type === "error" && (
            <CrossIcon color="error" className={styles.icon} />
          )}
          <Typography className={styles.message} variant="h6">
            {message}
          </Typography>
          {subMessage && (
            <Typography
              className={styles.subMessage}
              variant="subtitle2"
              color="text.secondary"
            >
              {subMessage}
            </Typography>
          )}
          <Grid container className={styles.table}>
            {tableRows &&
              tableRows.map(([title, value]) => (
                <React.Fragment key={title}>
                  <Grid item xs={5}>
                    <Typography color="text.secondary">{title}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>{value}</Typography>
                  </Grid>
                  <Divider className={styles.divider} />
                </React.Fragment>
              ))}
          </Grid>
          {hasAccessToDashboard && hasGoToDashboard && (
            <Button
              onClick={() => push("/experience/dashboard")}
              className={styles.button}
            >
              Go to Dashboard
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default Result;
