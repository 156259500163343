import { useLock } from "@/hooks";
import Alert from "../Alert";
import styles from "./PaymentLockAlert.module.scss";
const PaymentLockAlert: React.FC = () => {
  const { isPaymentLockEnabled } = useLock();
  return null; //temporarily, till text got confirmed

  return isPaymentLockEnabled ? (
    <Alert variant="standard" color="warning" className={styles.alertRoot}>
      The payment lock alert text here
    </Alert>
  ) : null;
};

export default PaymentLockAlert;
