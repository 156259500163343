import { useEffect, useState } from "react";
import { useTrackers } from "@/hooks";
import { useStripe } from "@stripe/react-stripe-js";
import { CircularProgress } from "@/components/Loader";
import type { ResultMessage } from "./Pay";

enum CurrentState {
  Initial,
  Authenticating,
  Completed,
}

const Authenticate: React.FC<{
  clientSecret: string;
  paymentIntentId: string;
  onResult: (message: ResultMessage) => void;
}> = ({ onResult, clientSecret, paymentIntentId }) => {
  const stripe = useStripe();

  const [currentState, setCurrentState] = useState(CurrentState.Initial);
  const { captureException } = useTrackers();

  useEffect(() => {
    const authenticate = async () => {
      if (
        !stripe ||
        currentState === CurrentState.Authenticating ||
        currentState === CurrentState.Completed
      ) {
        // Stripe.js has not yet loaded.
        return;
      }

      setCurrentState(CurrentState.Authenticating);
      const res = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentIntentId,
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      const { error } = res;
      if (error?.message) {
        onResult({ message: error.message, type: "error" });
        console.error(error);
        captureException(error);
      } else {
        onResult({
          type: "success",
          amount: (res.paymentIntent?.amount || 0) / 100,
          date: res.paymentIntent?.created,
          receiptId: res.paymentIntent?.id,
        });
      }

      setCurrentState(CurrentState.Completed);
    };

    authenticate();
  }, [
    captureException,
    clientSecret,
    currentState,
    onResult,
    paymentIntentId,
    stripe,
  ]);

  return null;
};

export default Authenticate;
