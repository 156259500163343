import { FC } from "react";
import cx from "classnames";
import MuiTypography from "@mui/material/Typography";
import styles from "./PageTitle.module.scss";
import { IconButton } from "@/components/Button";
import { ArrowIcon } from "@/components/Icon";

type Props = {
  title: string;
  color?: "primary" | "secondary";
  onGoBack?: undefined | (() => void);
  spacing?: "xs" | "s" | "m" | "l" | "xl";
  className?: string;
  visibleInMobile?: boolean;
};

const PageTitle: FC<Props> = ({
  title,
  color = "primary",
  spacing = "m",
  onGoBack,
  className,
  visibleInMobile = false,
}) => {
  return (
    <div
      className={cx(
        styles.root,
        {
          [styles.extraSmallSpacing]: spacing === "xs",
          [styles.smallSpacing]: spacing === "s",
          [styles.largeSpacing]: spacing === "l",
          [styles.extraLargeSpacing]: spacing === "xl",
          [styles.hideInMobile]: !visibleInMobile,
        },
        className
      )}
    >
      {Boolean(onGoBack) && (
        <IconButton onClick={onGoBack} className={styles.backButton}>
          <ArrowIcon dir="left" />
        </IconButton>
      )}
      <MuiTypography
        variant="h6"
        classes={{
          root: cx(styles.titleRoot, {
            [styles.secondary]: color === "secondary",
          }),
        }}
      >
        {title}
      </MuiTypography>
    </div>
  );
};

export default PageTitle;
