import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";

type Input = { time: Dayjs | number | undefined | null };

const CountdownTimer = ({ time }: Input) => {
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    const currentDate = dayjs();
    const date = dayjs(time);
    const secondsRemaining = date.unix() - currentDate.unix();
    setSeconds(secondsRemaining);
  }, [time]);

  useEffect(() => {
    // Exit early if countdown is finished
    if (seconds <= 0) {
      return;
    }

    // Set up the timer
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clean up the timer
    return () => clearInterval(timer);
  }, [seconds]);

  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds - hour * 3600) / 60);
  const second = seconds - hour * 3600 - minute * 60;
  return { hour, minute, second };
};

export default CountdownTimer;
