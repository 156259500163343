import Typography from "@/components/Typography";
import Button from "@/components/Button";
import Divider from "@/components/Divider";
import styles from "./BottomRow.module.scss";
import { useFormatCurrency } from "@/hooks";
import { CircularProgress } from "@/components/Loader";
import { useTransactionProcessFeesQuery } from "@/fetch/payment";

const BottomRow: React.FC<{
  isLoading?: boolean;
  isSubmitting?: boolean;
  errorMessage?: string | undefined;
  amount: number | undefined;
  currency: string | undefined;
  currencySymbol: string | undefined;
  buttonText?: string;
  onlyAddCardWithoutPayment: boolean;
}> = ({
  isLoading,
  isSubmitting,
  errorMessage,
  currency,
  amount,
  currencySymbol,
  buttonText,
  onlyAddCardWithoutPayment,
}) => {
  const { format } = useFormatCurrency();
  const { data, isLoading: isLoadingFees } = useTransactionProcessFeesQuery({
    currency: currency || "AUD",
    operation: "execute",
  });

  const currentProcessFee = data?.data?.[0];

  const hiddenFeeCurrencies = ["GBP", "EUR"];
  const isFeeHidden = Boolean(
    hiddenFeeCurrencies.find((each) => currency === each)
  );

  return (
    <div>
      {errorMessage && (
        <Typography
          color="error"
          id="payment-message"
          className={styles.message}
        >
          {errorMessage}
        </Typography>
      )}
      <Divider className={styles.divider} />
      <div className={styles.bottomRow}>
        <div style={{ flex: 1 }}>
          {amount !== null && currency !== "" && !onlyAddCardWithoutPayment && (
            <>
              {/* <div style={{ display: "flex", alignItems: "center" }}> */}
              <Typography variant="body2">Your payment today</Typography>

              {/* </div> */}

              <Typography variant="h6">
                {currency} {format(amount, currency, currencySymbol)}
              </Typography>
              {!isLoadingFees && !isFeeHidden && (
                <Typography variant="caption" color="text.secondary">
                  Card payments subject to a {currentProcessFee?.percent || 1}%
                  surcharge
                </Typography>
              )}
            </>
          )}
        </div>
        <Button
          endIcon={
            isSubmitting ? <CircularProgress color="inherit" /> : undefined
          }
          type="submit"
          disabled={isLoading || isSubmitting || isLoadingFees}
          id="submit"
        >
          {buttonText || "Pay"}
        </Button>
      </div>
    </div>
  );
};

export default BottomRow;
