import { Skeleton } from "@/components/Loader";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import styles from "./StatusIndicator.module.scss";
import { MoreIcon } from "@/components/Icon";
import Menu, { MenuItem } from "@/components/Menu";
import { ListItemText } from "@/components/List";
import { useState } from "react";
import cx from "classnames";

type StatusIndicatorProps = {
  isLoading: boolean;
  status?: string | undefined;
  icon?: React.ReactNode;
  className?: string;
};

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  isLoading,
  status,
  icon,
}) => {
  return isLoading ? (
    <>
      <Skeleton width="88px" />
      <Skeleton width="120px" style={{ marginTop: "8px" }} />
    </>
  ) : (
    <>
      <Typography variant="body1" color="text.secondary">
        Status
      </Typography>
      <Typography component="div" variant="body1" className={styles.statusText}>
        {Boolean(icon) && icon}
        {status || ""}
      </Typography>
    </>
  );
};

const MobileStatusIndicator: React.FC<
  StatusIndicatorProps & {
    actions?: Array<{ label: string; onClick: () => void; disabled?: boolean }>;
  }
> = ({ isLoading, status, actions, icon, className }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={cx(styles.mobileRoot, className)}>
        {status && (
          <div className={styles.rootStatusContainer}>
            <StatusIndicator
              status={status}
              isLoading={isLoading}
              icon={icon}
            />
          </div>
        )}
        {actions?.length && (
          <Button
            variant="text"
            color="inherit"
            endIcon={<MoreIcon />}
            onClick={(event) => handleClick(event)}
            className={styles.moreButton}
          >
            More
          </Button>
        )}
      </div>

      <Menu
        elevation={3}
        id="payment-invoice-more"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          className: styles.tripSelectorMenu,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {actions?.map((action) => (
          <MenuItem
            key={action.label}
            onClick={() => {
              if (!action.disabled) {
                action.onClick();
                handleClose();
              }
            }}
          >
            <ListItemText
              aria-disabled={action.disabled}
              primaryTypographyProps={{
                color: action.disabled ? "text.secondary" : "text.primary",
              }}
              aria-selected="true"
              primary={action.label}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default StatusIndicator;
export { MobileStatusIndicator };
