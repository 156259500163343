import styles from "./CardsList.module.scss";
import { Card as PaymentCard } from "@/fetch/payment";
import { ClickableCard } from "@/components/Card";
import Button from "@/components/Button";
import RadioButton from "@/components/Radio";
import Typography from "@/components/Typography";
import BottomRow from "./BottomRow";
import { FormEventHandler, useState } from "react";
import { CardIcon } from "@/components/Icon";

const CardsList: React.FC<{
  cards: Array<PaymentCard> | undefined;
  currency: string | undefined;
  amount?: number | undefined;
  currencySymbol?: string | undefined;
  onAddAnotherClick: () => void;
  onCardSelect: (cardId: number) => Promise<unknown>;
  primaryButtonText?: string;
  onlyAddCardWithoutPayment: boolean;
}> = ({
  cards,
  currency,
  currencySymbol,
  amount,
  onAddAnotherClick,
  onCardSelect,
  primaryButtonText,
  onlyAddCardWithoutPayment,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const selectedCard = cards?.[selectedIndex];
    if (!selectedCard) return;

    try {
      setIsSubmitting(true);
      await onCardSelect(selectedCard.id);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form id="cards" onSubmit={handleSubmit} className={styles.root}>
      <div>
        {/* <Typography variant="body1">Choose your payment method</Typography> */}
        <div className={styles.cards}>
          {cards?.map((card, index) => (
            <ClickableCard
              disabled={isSubmitting}
              key={card.id}
              onClick={() => setSelectedIndex(index)}
            >
              <RadioButton
                disabled={isSubmitting}
                checked={index === selectedIndex}
              />
              <CardIcon />
              <Typography variant="body1">•••• {card.suffix}</Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                className={styles.expiry}
              >
                Expiry
              </Typography>
              <Typography variant="body1">
                {card.expiry_month}/{card.expiry_year}
              </Typography>
            </ClickableCard>
          ))}
          <Button
            disabled={isSubmitting}
            variant="text"
            onClick={() => onAddAnotherClick()}
          >
            Use another method
          </Button>
        </div>
      </div>
      <BottomRow
        isLoading={cards?.length === 0}
        isSubmitting={isSubmitting}
        currencySymbol={currencySymbol}
        amount={amount}
        currency={currency}
        buttonText={primaryButtonText}
        onlyAddCardWithoutPayment={onlyAddCardWithoutPayment}
      />
    </form>
  );
};

export default CardsList;
