import { useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import type { ResultMessage } from "./Pay";

const StripeReturnedSetup: React.FC<{
  onResult: (message: ResultMessage) => void;
}> = ({ onResult }) => {
  const stripe = useStripe();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      switch (setupIntent?.status) {
        case "succeeded":
          onResult({
            message: "Success! Your payment method has been saved.",
            subMessage: "",
            hasGoToDashboard: false,
            type: "success",
          });
          break;
        case "processing":
          onResult({
            message:
              "Processing payment details. We'll update you when processing is complete.",
            type: "success",
            subMessage: "",
            hasGoToDashboard: false,
          });
          break;
        case "requires_payment_method":
          onResult({
            message:
              "Failed to process payment details. Please try another payment method.",
            type: "error",
            subMessage: "",
            hasGoToDashboard: false,
          });
          break;
        default:
          onResult({
            message: "Something went wrong.",
            type: "error",
            subMessage: "",
            hasGoToDashboard: false,
          });
          break;
      }
    });
  }, [stripe, onResult]);

  return null;
};

export default StripeReturnedSetup;
