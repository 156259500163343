import { FC } from "react";
import Dialog, { DialogTitle, DialogContent } from "@/components/Dialog";
import Typography from "@/components/Typography";
import { useOpenExternalURL } from "@/hooks";
import styles from "./AppPromotionDialog.module.scss";

const AppPromotionDialog: FC<{ onClose: () => void }> = ({ onClose }) => {
  const openExternalURL = useOpenExternalURL();

  const openAppStore = () => {
    openExternalURL(
      "https://apps.apple.com/us/app/global-work-travel/id1535421676?ign-itscg=30200&ign-itsct=apps_box_link"
    );
  };

  const openGooglePlay = () => {
    openExternalURL(
      "https://play.google.com/store/apps/details?id=com.globalworkandtravel.gapp"
    );
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{ className: styles.dialogRoot }}
    >
      <DialogTitle hasCloseButton onClose={onClose}></DialogTitle>
      <DialogContent className={styles.container}>
        <div className={styles.leftContainer}>
          <Typography variant="h4" className={styles.title}>
            Get the gApp
          </Typography>
          <Typography variant="body1" className={styles.description}>
            Download our official app to find a trip or keep planning yours
            whilst on the go from the palm of your hand.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Scan to download instantly!
          </Typography>
          <div className={styles.scanContainer}>
            <div className={styles.scanImageContainer}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={`/images/qr-code-scan.svg`}
                alt="icon"
                className={styles.codeImage}
              />
            </div>
            <div className={styles.instructionContainer}>
              <Typography variant="body2" className={styles.instructionTitle}>
                Directions:
              </Typography>
              <Typography variant="subtitle2">
                1. Open the camera app on your phone
              </Typography>
              <Typography variant="subtitle2">
                2. Scan the QR code to the left
              </Typography>
              <Typography variant="subtitle2">
                3. Follow the steps on your phone
              </Typography>
            </div>
          </div>
          <Typography variant="body2" color="text.secondary">
            Or find it on your favorite platform:
          </Typography>
          <div className={styles.storeLogoContainers}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={`/images/google-play-icon.svg`}
              alt="icon"
              className={styles.storeIcon}
              onClick={openGooglePlay}
            />
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={`/images/app-store-icon.svg`}
              alt="icon"
              className={styles.storeIcon}
              onClick={openAppStore}
            />
          </div>
        </div>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="https://gwatco-res.cloudinary.com/image/upload/v1729499122/gwat.gworld/assets/g_app_screenshot.png"
          alt="screenshot"
          className={styles.screenshotImage}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AppPromotionDialog;
