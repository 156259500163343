import SvgIcon, { SvgIconProps } from "./SvgIcon";

const MobileIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32" fill="none">
      <rect
        x="9"
        y="3"
        width="15"
        height="26"
        rx="2"
        strokeWidth="2"
        fill="transparent"
      />
      <path d="M18 25.5C18 24.6716 17.3284 24 16.5 24C15.6716 24 15 24.6716 15 25.5C15 26.3284 15.6716 27 16.5 27C17.3284 27 18 26.3284 18 25.5Z" />
      <rect x="13" y="6" width="7" height="1" rx="0.5" />
    </SvgIcon>
  );
};

export default MobileIcon;
