import {
  useStripePublishableKeys,
  useInitTxMutation,
  useCardsQuery,
  useExecuteCardPaymentMutation,
  useInvoiceQuery,
  useCardSetupMutation,
  useRetrieveIntent,
  useInvoiceHistoryQuery,
} from "@/fetch/payment";
import { Skeleton } from "@/components/Loader";
import { useCallback, useEffect, useMemo, useState } from "react";
import { loadStripe, Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import StripeReturnedPayment from "./StripeReturnedPayment";
import CardsList from "./CardsList";
import Grid from "@/components/Grid";
import { useTrackers } from "@/hooks";
import { useRouter } from "next/router";
import Result from "./Result";
import StripeReturnedSetup from "./StripeReturnedSetup";
import theme from "@/theme/theme";
import Authenticate from "./Authenticate";
import { CircularProgress } from "@/components/Loader";
import styles from "./Pay.module.scss";
import Typography from "@/components/Typography";
import BottomRow from "./BottomRow";

enum CurrentState {
  StripeReturnedPayment,
  StripeReturnedSetup,
  CardsList,
  CheckoutForm,
  Authenticate,
  Result,
}

const Loading: React.FC<{ message?: string }> = ({ message }) => (
  <div className={styles.loadingContainer}>
    <CircularProgress size="xl" />
    {message && <Typography>{message}</Typography>}
  </div>
);

export type ResultMessage =
  | {
      message?: string;
      subMessage?: string;
      hasGoToDashboard?: boolean;
      type: "success" | "error";
      amount?: number;
      date?: number;
      receiptId?: string;
    }
  | undefined;

const TextFieldLoader = <Skeleton variant="rectangular" height="56px" />;

const Pay: React.FC<{
  amount?: number | undefined;
  currency?: string | undefined;
  currencySymbol?: string | undefined;
  invoiceType?: "travel" | "trip" | undefined;
  invoiceStaffId?: number | undefined;
  invoiceId?: number | undefined;
  isFinal?: boolean;
  paymentPlanId?: number | undefined;
  promoCodes?: Array<string> | undefined;
  onCardSelect?: (cardId: number) => Promise<unknown>;
  onlyAddCardWithoutPayment?: boolean;
  setOnGoBack?: (onGoBack: null | (() => void)) => void;
}> = ({
  currency: currencyProp,
  currencySymbol,
  amount,
  invoiceId,
  invoiceStaffId,
  isFinal,
  invoiceType,
  paymentPlanId,
  promoCodes,
  onCardSelect,
  onlyAddCardWithoutPayment,
  setOnGoBack,
}) => {
  const [currency, setCurrency] = useState(currencyProp);
  const { refetch: refetchInvoiceHistory } = useInvoiceHistoryQuery(invoiceId, {
    enabled: false,
  });
  const { data: keys } = useStripePublishableKeys(currency);
  const { mutateAsync: initTx, isLoading: isInitTxLoading } =
    useInitTxMutation();
  const { mutateAsync: setupCard, isLoading: isSetupCardLoading } =
    useCardSetupMutation();
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [clientSecret, setClientSecret] = useState<string | undefined>(
    undefined
  );
  const [paymentIntentId, setPaymentMethodId] = useState("");
  const [currentState, setCurrentState] = useState<CurrentState | null>(null);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const { data: cardsData, isLoading: isCardsLoading } = useCardsQuery();
  const cards = cardsData?.cards.filter(
    (card) => card.visible && card.currency === currency
  );
  const hasCards = cards && cards.length > 0;
  const { mutateAsync: executeCardPaymentMutation } =
    useExecuteCardPaymentMutation();
  const { refetch: refetchInvoice } = useInvoiceQuery(invoiceId);
  const { track } = useTrackers();
  const retrieveIntent = useRetrieveIntent();

  const { pathname, query, replace } = useRouter();
  const removePaymentIntent = useCallback(() => {
    const nextQuery = { ...query };
    delete nextQuery.payment_intent;
    delete nextQuery.setup_intent;
    delete nextQuery.payment_intent_client_secret;
    delete nextQuery.setup_intent_client_secret;
    delete nextQuery.redirect_status;
    replace({ pathname, query: nextQuery }, undefined, { scroll: false });
  }, [pathname, query, replace]);

  // I did not use `query` because sometimes it
  // returned empty objects
  const queryParams = useMemo(
    () => new URLSearchParams(global.window.location.search),
    []
  );

  useEffect(() => {
    if (!setOnGoBack) return;

    if (currentState === CurrentState.CheckoutForm) {
      setOnGoBack(() => () => {
        setCurrentState(CurrentState.CardsList);
      });
    } else {
      setOnGoBack(null);
    }
  }, [currentState, setOnGoBack]);

  useEffect(() => {
    const currencyParam = queryParams.get("currency");

    if (currencyParam) setCurrency(currencyParam);
    if (currencyProp) setCurrency(currencyProp);
  }, [currencyProp, queryParams]);

  const [message, setMessage] = useState<ResultMessage>();
  const onResult = (result: ResultMessage) => {
    setMessage(result);
    setCurrentState(CurrentState.Result);
    refetchInvoiceHistory();
  };

  const executeCardPayment = async (cardId: number) => {
    try {
      // validation
      if (!amount || !invoiceId || !invoiceType) return;

      const res = await executeCardPaymentMutation({
        cardId,
        amount,
        isFinal: !!isFinal,
        invoiceId,
        invoiceType,
        invoiceStaffId,
        paymentPlanId,
        promoCodes,
      });
      track("Payment Transaction Completed", {
        eventId: "payment-transaction-completed",
        transaction: {
          cardId,
          amount,
          isFinal,
          invoiceId,
          invoiceType,
          invoiceStaffId,
          paymentPlanId,
          promoCodes,
        },
      });
      await refetchInvoice();
      onResult({
        type: "success",
        amount: res.transactions[0].amount,
        date: Number(res.transactions[0].transaction_date),
        receiptId: res.transactions[0].transaction_id,
      });

      return res;
    } catch (error: any) {
      const paymentIntent = error.response?.data?.extraData?.paymentIntent;
      const lastError = paymentIntent?.last_payment_error;
      if (lastError?.code === "authentication_required") {
        setCurrentState(CurrentState.Authenticate);
        setClientSecret(paymentIntent.client_secret);
        setPaymentMethodId(lastError.payment_method.id);
        return;
      }
      onResult({ type: "error", message: error.message });
      console.error(error);
      track("Payment Transaction Failed", {
        eventId: "payment-transaction-failed",
        data: error,
      });
    }
  };

  useEffect(() => {
    if (keys?.publishable) {
      try {
        setStripePromise(loadStripe(keys.publishable));
      } catch (error) {
        onResult({
          type: "error",
        });
        console.error(error);
      }
    }
  }, [keys]);

  useEffect(() => {
    const getClientSecret = async () => {
      if (currentState === CurrentState.Result) return;

      try {
        const paymentIntentToAuthenticate =
          queryParams.get("payment_intent_id");
        const methodId = queryParams.get("payment_method_id");

        // We a payment to authenticate using 3DS
        if (paymentIntentToAuthenticate && currency && methodId) {
          const intent = await retrieveIntent({
            currency,
            intentId: paymentIntentToAuthenticate,
          });
          const clientSecret = intent.client_secret;
          setPaymentMethodId(methodId);
          setClientSecret(clientSecret);
          setCurrentState(CurrentState.Authenticate);
          return;
        }

        const returnedPaymentClientSecret = query.payment_intent_client_secret;
        // We Have returned from a payment
        if (returnedPaymentClientSecret) {
          setClientSecret(returnedPaymentClientSecret as string);
          setCurrentState(CurrentState.StripeReturnedPayment);
          return;
        }

        const returnedSetupClientSecret = query.setup_intent_client_secret;

        // We Have returned from a setup card
        if (returnedSetupClientSecret) {
          const redirectStatus = query.redirect_status;
          if (redirectStatus === "succeeded") {
            // This can be changed later to show list of cards when a
            // new card is added.
            setClientSecret(returnedSetupClientSecret as string);
            setCurrentState(CurrentState.StripeReturnedSetup);
            // setCurrentState(CurrentState.CardsList);
            return;
          } else {
            setClientSecret(returnedSetupClientSecret as string);
            setCurrentState(CurrentState.StripeReturnedSetup);
            return;
          }
        }

        if (onlyAddCardWithoutPayment && currency && !clientSecret) {
          const res = await setupCard({ currency });
          setClientSecret(res.client_secret);
          setCurrentState(CurrentState.CardsList);
          return;
        }

        // validation
        if (!amount || !invoiceId || !invoiceType) return;

        // Only initTx if there is no card, or
        // we are explicitly on the checkout form
        if (
          (isCardsLoading || hasCards) &&
          currentState !== CurrentState.CheckoutForm
        )
          return;

        const res = await initTx({
          amount,
          isFinal: !!isFinal,
          invoiceId,
          invoiceType,
          invoiceStaffId,
          paymentPlanId,
          promoCodes,
        });
        if (res.clientSecret) setClientSecret(res.clientSecret);
      } catch (err: any) {
        onResult({
          type: "error",
          message: err.message,
        });
        console.error(err);
      }
    };

    getClientSecret();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCardsLoading,
    hasCards,
    amount,
    isFinal,
    invoiceId,
    invoiceType,
    invoiceStaffId,
    paymentPlanId,
    initTx,
    currentState,
    promoCodes,
    onlyAddCardWithoutPayment,
    currency,
    setupCard,
    removePaymentIntent,
    currentState,
  ]);

  // Display cards list when if it has cards
  useEffect(() => {
    if (currentState !== null && currentState !== CurrentState.CardsList)
      return;
    if (isCardsLoading) return;

    if (hasCards) {
      setCurrentState(CurrentState.CardsList);
    } else {
      setCurrentState(CurrentState.CheckoutForm);
    }
  }, [isCardsLoading, hasCards, currentState]);

  let baseUrl = window.location.origin;

  // for dev only
  if (baseUrl.includes("3000")) {
    baseUrl = "https://gworld-next.globalworkandtravel.com";
  }

  const options: StripeElementsOptions = {
    clientSecret,
    fonts: [
      {
        family: "ProximaNova",
        weight: "300",
        display: "swap",
        style: "normal",
        src: `url("https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")`,
      },
      {
        family: "ProximaNova",
        weight: "400",
        display: "swap",
        style: "normal",
        src: `url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")`,
      },
      {
        family: "ProximaNova",
        weight: "500",
        display: "swap",
        style: "normal",
        src: `url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")`,
      },
      {
        family: "ProximaNova",
        weight: "600",
        display: "swap",
        style: "normal",
        src: `url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")`,
      },
      {
        family: "ProximaNova",
        weight: "700",
        display: "swap",
        style: "normal",
        src: `url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")`,
      },
    ],
    appearance: {
      theme: "stripe",
      rules: {
        ".Input": {
          padding: "16px 12px",
        },
        ".Label": {
          color: theme.palette.text.secondary,
        },
      },
      variables: {
        colorPrimary: "#0095cd",
        fontFamily: "ProximaNova",
        borderRadius: "8px",
        colorText: "#0f0f0f",
        fontSizeBase: "18px",
        fontWeightNormal: "600",
      },
    },
  };

  let isLoading = true;
  switch (currentState) {
    case CurrentState.Result:
      isLoading = false;
    case CurrentState.CardsList:
      isLoading = isCardsLoading;
      break;
    case CurrentState.StripeReturnedPayment:
    case CurrentState.StripeReturnedSetup:
    case CurrentState.Authenticate:
      isLoading = !stripePromise || !clientSecret;
      break;
    case CurrentState.CheckoutForm:
      isLoading = !stripePromise || !clientSecret || isCardsLoading;
      break;
    default:
      isLoading = true;
  }

  // console.log({
  //   stripePromise,
  //   clientSecret,
  //   isCardsLoading,
  //   message,
  //   isLoading,
  //   keys,
  //   currentState,
  // });

  if (currentState === CurrentState.Result && message)
    return (
      <Result
        {...message}
        currencySymbol={currencySymbol}
        currency={currency}
      />
    );

  if (isLoading && currentState === CurrentState.CheckoutForm)
    return (
      <>
        <Grid sx={{ mt: 1 }} container spacing="24px" columnSpacing="12px">
          <Grid item xs={12} md={12}>
            {TextFieldLoader}
          </Grid>
          <Grid item xs={12} md={12}>
            {TextFieldLoader}
          </Grid>
          <Grid item xs={12} md={6}>
            {TextFieldLoader}
          </Grid>
          <Grid item xs={12} md={6}>
            {TextFieldLoader}
          </Grid>
          <Grid item xs={12} md={6}>
            {TextFieldLoader}
          </Grid>
          <Grid item xs={12} md={6}>
            {TextFieldLoader}
          </Grid>
        </Grid>
        <Skeleton sx={{ mt: 3 }} variant="text" />
        <Skeleton variant="text" sx={{ mb: 8 }} />
        <BottomRow
          onlyAddCardWithoutPayment={false}
          amount={amount}
          currency={currency}
          currencySymbol={currencySymbol}
          isLoading
        />
      </>
    );

  if (isLoading) {
    return <Loading />;
  }

  if (currentState === CurrentState.CardsList)
    return (
      <CardsList
        primaryButtonText={onlyAddCardWithoutPayment ? "Select" : undefined}
        onlyAddCardWithoutPayment={!!onlyAddCardWithoutPayment}
        cards={cards}
        currency={currency}
        currencySymbol={currencySymbol}
        amount={amount}
        onAddAnotherClick={() => setCurrentState(CurrentState.CheckoutForm)}
        onCardSelect={(id) => {
          if (onCardSelect) return onCardSelect(id);
          return executeCardPayment(id);
        }}
      />
    );

  if (!clientSecret || !stripePromise) return null;

  return (
    <Elements options={options} stripe={stripePromise}>
      {currentState == CurrentState.CheckoutForm && (
        <CheckoutForm
          onReady={() => setIsFormLoading(false)}
          currency={currency}
          currencySymbol={currencySymbol}
          onlyAddCardWithoutPayment={!!onlyAddCardWithoutPayment}
          amount={amount}
        />
      )}
      {currentState === CurrentState.StripeReturnedPayment && (
        <>
          <StripeReturnedPayment onResult={onResult} />
          <Loading message="Processing..." />
        </>
      )}
      {currentState === CurrentState.StripeReturnedSetup && (
        <>
          <StripeReturnedSetup onResult={onResult} />
          <Loading message="Processing..." />
        </>
      )}
      {currentState === CurrentState.Authenticate && (
        <>
          <Authenticate
            clientSecret={clientSecret}
            paymentIntentId={paymentIntentId}
            onResult={onResult}
          />
          <Loading message="Processing..." />
        </>
      )}
    </Elements>
  );
};

export default Pay;
