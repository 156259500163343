import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  usePlanOptionsQuery,
  useInvoicesQuery,
  PlanOption,
} from "@/fetch/payment";
import { useDialog, useLock, useCurrentTrip } from "@/hooks";
import { TopLevelDialogNames } from "@/components/TopLevelDialogs";

const usePromotionHandler = () => {
  const [currentTripId, setCurrentTripId] = useState<number>(0);
  const { data: invoices, isLoading: isLoadingInvoices } = useInvoicesQuery();
  const tripInvoice = invoices?.find((i) => i.type === "trip");

  const { isLoading: isLoadingPlans, data } = usePlanOptionsQuery({
    invoiceId: tripInvoice?.id,
  });

  const { isAllowedToNavigate } = useLock();

  const { currentTrip } = useCurrentTrip();

  useEffect(() => {
    if (!currentTrip) return;
    setTimeout(() => setCurrentTripId(currentTrip.id || 0), 1000);
  }, [currentTrip]);

  const { openTopLevelDialog } = useDialog();

  useEffect(() => {
    if (isLoadingInvoices || isLoadingPlans) return;
    if (!invoices || !data || !isAllowedToNavigate) return;
    const isFullPlanOption = ({ option }: PlanOption) =>
      option.count === 1 && option.unit === "day" && option.interval === 1;

    const fullOption = data?.paymentOptions?.find(isFullPlanOption);

    const promotionObj = fullOption?.option.promotionObject;
    const currentDate = dayjs();
    const endDate = promotionObj?.conditions.end_at || 0;
    const expireDate = promotionObj?.conditions.expire_at || 0;
    const selectedDate = endDate < expireDate ? endDate : expireDate;
    const counterDate = dayjs((selectedDate || 0) * 1000);

    const showBanner = promotionObj?.show_banner_countdown;

    const isRemaining = currentDate.isBefore(counterDate);
    if (isRemaining) {
      openTopLevelDialog(TopLevelDialogNames.Upgrade);
    }
  }, [
    data,
    invoices,
    isAllowedToNavigate,
    currentTripId,
    isLoadingPlans,
    isLoadingInvoices,
  ]);
};

export default usePromotionHandler;
