import Dialog, { DialogTitle, DialogContent } from "@/components/Dialog";
import styles from "./PayDialog.module.scss";
import { useDialog } from "@/hooks";
import { IconButton } from "@/components/Button";
import { ArrowIcon } from "@/components/Icon";
import Pay from "./Pay";
import { useState } from "react";

const PayDialog: React.FC<{
  open: boolean;
  amount?: number | undefined;
  currency: string | undefined;
  currencySymbol: string | undefined;
  invoiceType?: "travel" | "trip" | undefined;
  invoiceStaffId?: number | undefined;
  invoiceId?: number | undefined;
  isFinal?: boolean;
  paymentPlanId?: number | undefined;
  promoCodes?: Array<string> | undefined;
  onCardSelect?: (cardId: number) => Promise<unknown>;
  onlyAddCardWithoutPayment?: boolean;
}> = ({
  open,
  currency,
  currencySymbol,
  amount,
  invoiceId,
  invoiceStaffId,
  isFinal,
  invoiceType,
  paymentPlanId,
  promoCodes,
  onCardSelect,
  onlyAddCardWithoutPayment,
}) => {
  const { onClose: closeDialog } = useDialog("pay");

  const onClose = () => {
    closeDialog({
      payment_intent: "",
      setup_intent: "",
      payment_intent_client_secret: "",
      setup_intent_client_secret: "",
      redirect_status: "",
    });
  };

  const [onGoBack, setOnGoBack] = useState<null | (() => void)>(null);

  return (
    <Dialog open={open}>
      <DialogTitle onClose={onClose}>
        {onGoBack && (
          <IconButton onClick={onGoBack} className={styles.backButton}>
            <ArrowIcon dir="left" />
          </IconButton>
        )}
        Payment
      </DialogTitle>
      <DialogContent className={styles.content}>
        {open && (
          <Pay
            setOnGoBack={setOnGoBack}
            currency={currency}
            currencySymbol={currencySymbol}
            amount={amount}
            invoiceId={invoiceId}
            isFinal={isFinal}
            invoiceStaffId={invoiceStaffId}
            onCardSelect={onCardSelect}
            onlyAddCardWithoutPayment={onlyAddCardWithoutPayment}
            paymentPlanId={paymentPlanId}
            promoCodes={promoCodes}
            invoiceType={invoiceType}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PayDialog;
