import { FormEventHandler, useEffect, useState } from "react";
import styles from "./CheckoutForm.module.scss";
import { useTrackers } from "@/hooks";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import BottomRow from "./BottomRow";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import TextField from "@/components/TextField";
import Typography from "../Typography";

const CheckoutForm: React.FC<{
  amount: number | undefined;
  currency: string | undefined;
  currencySymbol: string | undefined;
  onReady: () => void;
  onlyAddCardWithoutPayment: boolean;
}> = ({
  currency,
  currencySymbol,
  amount,
  onReady,
  onlyAddCardWithoutPayment,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { captureException } = useTrackers();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const [cardHolderName, setCardHolderName] = useState("");

  useEffect(() => {
    if (!personalDetails) return;
    const traveller = personalDetails.traveller;
    setCardHolderName(
      `${traveller.first_name} ${traveller.middle_name || ""} ${
        traveller.last_name
      }`
    );
  }, [personalDetails]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsSubmitting(true);
    setMessage(undefined);

    let res;
    if (onlyAddCardWithoutPayment) {
      res = await stripe.confirmSetup({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: cardHolderName,
            },
          },
          return_url: window.location.href,
        },
      });
    } else {
      res = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: cardHolderName,
            },
          },
          return_url: window.location.href,
        },
      });
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    const { error } = res;
    if (error.message) {
      setMessage(error.message);
      console.error(error);
      captureException(error);
    }

    setIsSubmitting(false);
  };

  const isLoading = !stripe || !elements;

  return (
    <form id="payment-form" onSubmit={handleSubmit} className={styles.form}>
      {stripe && elements && (
        <>
          <Typography
            color="text.secondary"
            style={{
              paddingBottom: "0.25rem",
            }}
          >
            Name
          </Typography>
          <TextField
            value={cardHolderName}
            onChange={(e) => setCardHolderName(e.target.value)}
            helperText={!cardHolderName && "Enter card holder name"}
            error={!cardHolderName}
          />
        </>
      )}
      <PaymentElement
        id="payment-element"
        className={styles.paymentElement}
        onReady={onReady}
        options={{ fields: { billingDetails: { name: "never" } } }}
      />
      <BottomRow
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        errorMessage={message}
        currencySymbol={currencySymbol}
        amount={amount}
        currency={currency}
        buttonText={onlyAddCardWithoutPayment ? "Add" : undefined}
        onlyAddCardWithoutPayment={onlyAddCardWithoutPayment}
      />
    </form>
  );
};

export default CheckoutForm;
