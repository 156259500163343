import SvgIcon, { SvgIconProps } from "./SvgIcon";

const ShieldIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M12 22C9.39375 21.4167 7.24219 20.0875 5.54531 18.0125C3.84844 15.9375 3 13.6333 3 11.1V5.72076C3 5.29033 3.27543 4.90819 3.68377 4.77208L11.6838 2.10541C11.889 2.03699 12.111 2.03699 12.3162 2.10541L20.3162 4.77208C20.7246 4.90819 21 5.29033 21 5.72076V11.1C21 13.6333 20.1516 15.9375 18.4547 18.0125C16.7578 20.0875 14.6062 21.4167 12 22ZM12 19.9C13.95 19.35 15.5625 18.25 16.8375 16.6C18.1125 14.95 18.75 13.1167 18.75 11.1V6.375L12 4.125L5.25 6.375V11.1C5.25 13.1167 5.8875 14.95 7.1625 16.6C8.4375 18.25 10.05 19.35 12 19.9Z" />
    </SvgIcon>
  );
};

export default ShieldIcon;
