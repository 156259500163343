import { useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import type { ResultMessage } from "./Pay";

const StripeReturnedPayment: React.FC<{
  onResult: (message: ResultMessage) => void;
}> = ({ onResult }) => {
  const stripe = useStripe();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          onResult({
            amount: paymentIntent.amount / 100,
            date: paymentIntent.created,
            receiptId: paymentIntent.id,
            type: "success",
          });
          break;
        case "processing":
          onResult({
            message: "Your payment is processing.",
            type: "success",
          });
          break;
        case "requires_payment_method":
          onResult({
            type: "error",
          });
          break;
        default:
          onResult({
            type: "error",
          });
          break;
      }
    });
  }, [stripe, onResult]);

  return null;
};

export default StripeReturnedPayment;
